<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-07-04 05:01:21
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-26 14:30:38
-->
<template>
  <div class="container">
    <div class="d-flex pt-2 pb-2 pl-2">
      <div class="header-optimizedDecision">
        课程评分排名
      </div>
    </div>

    <div class="d-flex jc-center">
      <dv-scroll-ranking-board :config="config" style="width:80%;height:250px" />
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        config: {}
      };
    },
    props: {
      originData: {
        type: [Object, Array],
        default: () => {
          return {}
        },
      },
    },
    watch: {
      originData: {
        handler(val) {
          this.config = {
            data: val.map(item=>{
              return {
                name:item.courseName,
                value:item.score
              }
            }),
            unit: ' 分'
          }
        },
        deep: true,
        immediate: true
      }
    }
  };
</script>

<style lang="scss" scoped>
  $box-height: 250px;

  .container {
    height: $box-height;

    .nav {
      margin: 5px 0;

      .customBtn {
        width: 20%;
      }
    }
  }
</style>