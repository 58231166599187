<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-07-03 18:46:35
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-29 17:13:08
-->
<template>
  <div>
    <Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options" height="160px" width="420px"></Echart>
  </div>
</template>

<script>
  import Echart from "@/common/echart";
  import {
    getRoudom
  } from "@/utils";
  export default {
    data() {
      return {
        options: {},
      };
    },
    components: {
      Echart,
    },
    props: {
      originData: {
        type: [Array, Object],
        default: () => [],
      },
    },
    watch: {
      originData: {
        handler(originData) {

          this.options = {
            // title: {
            //   text: 'World Population'
            // },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            // legend: {},
            grid: {
              top: '0%',
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'value',
              boundaryGap: [0, 0.01]
            },
            yAxis: {
              type: 'category',
              data: originData.map(i => i.areaName)
            },
            series: [{
              name: '2011',
              type: 'bar',
              data: originData.map(i => i.passCount),
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#83bff6'
                  },
                  {
                    offset: 0.5,
                    color: '#188df0'
                  },
                  {
                    offset: 1,
                    color: '#188df0'
                  }
                ])
              },
              emphasis: {
                itemStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#2378f7'
                    },
                    {
                      offset: 0.7,
                      color: '#2378f7'
                    },
                    {
                      offset: 1,
                      color: '#83bff6'
                    }
                  ])
                }
              },
            }]
          }
        },
        immediate: true,
        deep: true,
      },
    },
    methods: {
      getRoudom
    },
  };
</script>