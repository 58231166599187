<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-07-03 17:17:48
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-26 14:16:12
-->
<template>
  <div class="container">
    <div class="d-flex pt-2 pb-2 pl-2">
      <div class="header-optimizedDecision">
        年度营收分析
      </div>
    </div>

    <div class="content">
      <p class="d-flex jc-between ai-center">
        <span>今日收入：<span class="text-warning">{{originData.todayIncome}}元</span></span>

        <span>环比： <span
            :class="[originData.todayIsUp ? 'text-danger':'text-success']">{{(originData.todayRate || 0).toFixed(2)}}%
            {{upOrDown(originData.todayIsUp)}}</span>
        </span>
      </p>
      <p class="d-flex jc-between ai-center">
        <span>昨日收入：<span class="text-warning">{{originData.yesterdayIncome}}元</span></span>

        <span>环比：

          <span
            :class="[originData.yesterdayIsUp ? 'text-danger':'text-success']">{{(originData.yesterdayRate || 0).toFixed(2)}}%
            {{upOrDown(originData.yesterdayIsUp)}}</span></span>
      </p>

      <p class="d-flex jc-between ai-center">
        <span>本周收入：<span class="text-warning">{{originData.thisWeekIncome}}元</span></span>

        <span>环比：
          <span
            :class="[originData.thisWeekIsUp ? 'text-danger':'text-success']">{{(originData.thisWeekRate || 0).toFixed(2)}}%
            {{upOrDown(originData.thisWeekIsUp)}}</span>
        </span>
      </p>

      <p class="d-flex jc-between ai-center">
        <span>上周收入：<span class="text-warning">{{originData.preWeekIncome}}元</span></span>

        <span>环比：

          <span :class="[originData.preWeekIsUp ? 'text-danger':'text-success']">{{(originData.preWeekRate || 0).toFixed(2)}}%
            {{upOrDown(originData.preWeekIsUp)}}</span></span>
      </p>

      <p class="d-flex jc-between ai-center">
        <span>本月收入：<span class="text-warning">{{originData.thisMonthIncome}}元</span></span>

        <span>环比：

          <span
            :class="[originData.thisMonthIsUp ? 'text-danger':'text-success']">{{(originData.thisMonthRate || 0).toFixed(2)}}%
            {{upOrDown(originData.thisMonthIsUp)}}</span>
        </span>
      </p>

      <p class="d-flex jc-between ai-center">
        <span>上月收入：<span class="text-warning">{{originData.preMonthIncome}}元</span></span>

        <span>环比：

          <span
            :class="[originData.preMonthIsUp ? 'text-danger':'text-success']">{{(originData.preMonthRate || 0).toFixed(2)}}%
            {{upOrDown(originData.preMonthIsUp)}}</span>
        </span>
      </p>

      <p class="d-flex jc-between ai-center">
        <span>今年收入：<span class="text-warning">{{originData.thisYearIncome}}元</span></span>

        <span>环比：

          <span :class="[originData.thisYearIsUp ? 'text-danger':'text-success']">
            {{originData.thisYearRate}}%
            {{upOrDown(originData.thisYearIsUp)}}
          </span>
        </span>
      </p>

      <p class="d-flex jc-between ai-center">
        <span>去年收入：<span class="text-warning">{{originData.preYearIncome}}元</span></span>

        <span>环比：

          <span :class="[originData.preYearIsUp ? 'text-danger':'text-success']">{{originData.preYearRate}}%
            {{upOrDown(originData.preYearIsUp)}}</span>
        </span>
      </p>

    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    methods: {
      upOrDown(type) {
        if (type) return '↑'
        return '↓'
      }
    },
    props: {
      originData: {
        type: Object,
        default: () => {
          return {}
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  $box-height: 320px;

  .container {
    height: $box-height;
    position: relative;

    .content {
      padding: 0 30px;

      p {
        color: white;
        font-size: 20px;
        font-weight: 900;
        text-align: left;
        padding: 5px;
      }
    }
  }
</style>