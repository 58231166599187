<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-07-03 18:46:35
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-26 15:28:22
-->
<template>
  <div>
    <Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options" height="150px" width="440px"></Echart>
  </div>
</template>

<script>
  import Echart from "@/common/echart";
  import {
    getRoudom
  } from "@/utils";
  export default {
    data() {
      return {
        options: {},
      };
    },
    components: {
      Echart,
    },
    props: {
      originData: {
        type: [Array, Object],
        default: () => [],
      },
    },
    watch: {
      originData: {
        handler(originData) {

          this.options = {
            color: ['#4BDBFF', '#E2EB6E', '#2AB7FE'],
            tooltip: {
              trigger: "axis",
            },
            // legend: {
            //   data: [
            //     "日常调度",
            //     "实时调度"
            //   ],
            //   bottom: "5%",
            // },
            grid: {
              top: '30%',
              left: "0%",
              right: "8%",
              bottom: "15%",
              containLabel: true,
            },
            xAxis: {
              name: '小时',
              nameTextStyle: {
                color: "#ffffff",
                fontSize: 16
              },
              type: "category",
              boundaryGap: false,
              ...this.$xAixs(24)
            },
            yAxis: [{
              name: '学习人数',
              type: "value",
              nameTextStyle: {
                color: "#ffffff",
                fontSize: 16,
                align: 'left'
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
            }],
            series: [{
              name: "学习人数",
              type: "line",
              stack: "Total",
              data: originData.lineData.map(i=>i.count),
              smooth: 0.6,
              lineStyle: {
                color: '#4BDBFF',
                width: 5
              },
              areaStyle: {
                opacity: 0.2,
              },
            }],
          };
        },
        deep: true,
      },
    },
    methods: {
      getRoudom
    },
  };
</script>